export enum Methods {
	POST = "POST",
	GET = "GET",
	PUT = "PUT",
	DELETE = "DELETE",
	OPTIONS = "OPTIONS",
	PATCH = "PATCH",
}

type RequestMethods = `${Methods}`;

export enum ApiModules {
	Accounts = "accounts",
	Actions = "actions",
	Auth = "auth",
	Bot = "bot",
	CandleAnalytics = "candle-analytics",
	CEXLauncher = "cexlauncher",
	CEXTaker = "cextaker",
	Chart = "chart",
	Conditions = "conditions",
	Dashboard = "dashboard",
	DEX = "dex",
	Funding = "funding",
	Grid = "grid",
	MarketCheck = "marketcheck",
	MultiGrinder = "multigrinder",
	NEDEX = "nedex",
	Parties = "parties",
	Party = "party",
	Strategies = "strategies",
	SwapBot = "swap-bot",
	SwapBots = "swap-bots",
	Terminal = "terminal",
	Tickers = "tickers",
	WatchList = "watchlist",
	AddressBook = "addressbook",
	Empty = "",
}

export type ApiResponseBody = {
	status: number;
	data: string;
	success: boolean;
};

export type ApiResponseError = {
	status: number;
	error: string;
	success: boolean;
};

export type ApiResponse = {
	status: number;
	data: string | ApiResponseError | ApiResponseBody;
	route: string;
	headers?: { Authorization: string };
};

export type Authentication = { token: string } | false | undefined;

export interface RequestConfig {
	baseUrl?: string;
	apiPrefix?: string;
	url: string;
	method: RequestMethods;
	data?: any;
	auth?: Authentication;
	headers?: Record<string, string>;
	validateDataScheme?: boolean;
}

export interface ResponseData<Data> {
	response: Response;
	result: Data;
}

export interface ErrorInfo {
	status: number | null;
	code: number | "";
	request: string;
	message: string;
}

export interface ErrorStack extends ErrorInfo {
	date: string;
}

export type SortDir = "asc" | "desc";

export interface SortParams<T extends string = string> {
	sort_by: T;
	sort_dir: SortDir;
}

export interface FilterParams<T extends string = string> {
	filter_key: T;
	filter_val: string;
}

export interface PaginationParams {
	page: string;
	limit: string;
}

export interface PaginationMetaResponse {
	count: number;
	page: number;
	pages: number;
	per_page: number;
}

export interface SortFilterPaginationParams<
	Sort extends string = string,
	Filter extends string = string,
> extends SortParams<Sort>,
		FilterParams<Filter>,
		PaginationParams {}

export interface ErrorData extends ErrorInfo {
	toastId?: string | undefined;
}

type ApiSuccess<R = any> = { isError: false; data: R };

type ApiError<E = any> = { isError: true; data: E };

export type ApiResponseGeneric<R = any, E = any> = ApiSuccess<R> | ApiError<E>;
