// ABILITIES

export enum MainPagesAbilities {
	MarketCheckView = "party-view",

	AccessView = "auth-view",
	AccessEdit = "auth-edit",
	AccessRoleEdit = "auth-role-edit",
	AccessApiView = "cex-api-view-secret",

	TeamsView = "auth-user-group-view",
	TeamsEdit = "auth-user-group-edit",

	CEXCreatingView = "bot-manager",

	DEXCreatingView = "bot-manager",

	DEXNECreatingView = "dex-non-evm-manage",

	AccountingView = "accounting-view",
}

export enum PartyPagesAbilities {
	DashboardView = "cex-stats-view",

	DashboardAccountingView = "pnl-accounting-view",
	DashboardAccountingEdit = "pnl-accounting-edit",

	StrategiesView = "cex-expert-system-view",
	StrategiesEdit = "cex-expert-system-edit",

	ModulesView = "cex-modules-view",
	ModulesEdit = "cex-modules-edit",

	MultigridView = "cex-grid-view",
	MultigridEdit = "cex-grid-edit",

	FundingView = "cex-funding-view",
	FundingEdit = "cex-funding-edit",

	CandleAnalyticsView = "cex-candle-analytics-view",
	CandleAnalyticsEdit = "cex-candle-analytics-edit",

	MarketCheck = "cex-market-check-view",
	MarketCheckConfigView = "cex-market-check-settings-view",
	MarketCheckConfigEdit = "cex-market-check-settings-edit",

	LauncherView = "cexlauncher-view",
	LauncherEdit = "cexlauncher-edit",

	ArbitrageView = "cex-exchange-view",
	ArbitrageTrade = "cex-exchange-trade",

	AlgoArbitrageView = "cex-taker-view",
	AlgoArbitrageEdit = "cex-taker-edit",

	WithdrawalClientView = "withdrawal-client-view",
	WithdrawalClientEdit = "withdrawal-client-edit",
}

export enum CEXAbilities {
	StatsView = "cex-stats-view-deprecated",

	SettingsView = "cex-settings-view",
	SettingsEdit = "cex-settings-edit",

	GridView = "cex-grid-view",
	GridEdit = "cex-grid-edit",

	ApiKeysView = "cex-api-view",
	ApiKeysEdit = "cex-api-edit",

	ExchangeView = "cex-exchange-view",
	ExchangeTrade = "cex-exchange-trade",

	BotManage = "bot-manager",

	NotesView = "cex-notes-view",
	NotesEdit = "cex-notes-edit",
}

export enum DEXAbilities {
	StatsView = "dex-stats-view",

	SettingsView = "dex-settings-view",
	SettingsEdit = "dex-settings-edit",

	FundingView = "dex-funding-view",
	FundingEdit = "dex-funding-edit",

	NotesView = "dex-notes-view",
	NotesEdit = "dex-notes-edit",
}

export enum DEXV2Ability {
	StatsView = "dex-v2-stats-view",
	ExchangeView = "dex-v2-exchange-view",
	ExchangeTrade = "dex-v2-exchange-trade",

	NotesView = "dex-v2-notes-view",
	NotesEdit = "dex-v2-notes-edit",

	SettingsView = "dex-v2-settings-view",
	SettingsEdit = "dex-v2-settings-edit",
	InfoSettingsView = "dex-v2-info-settings-view",

	TransferWalletsView = "dex-v2-transfer-wallets-view",
	TransferWalletsEdit = "dex-v2-transfer-wallets-edit",
	SwapWalletsView = "dex-v2-swap-wallets-view",
	SwapWalletsEdit = "dex-v2-swap-wallets-edit",

	WalletsView = "dex-v2-wallets-view",
	WalletsGasWithdraw = "dex-v2-wallets-gas-withdraw",
	WalletsGasFill = "dex-v2-wallets-gas-fill",
	VaultsView = "dex-v2-vaults-view",
	VaultsTransfer = "dex-v2-vaults-transfer",
	VaultsWithdraw = "dex-v2-vaults-withdraw",

	VolumeHashView = "dex-v2-volume-hash-view",
	LimitHashView = "dex-v2-limit-hash-view",
	CounterHashView = "dex-v2-counter-hash-view",
	WalletsHashView = "dex-v2-wallets-hash-view",

	BotManage = "dex-v2-bot-manage",
}

export enum DEXNEAbility {
	StatsView = "dex-non-evm-stats-view",
	SettingsView = "dex-non-evm-settings-view",
	SettingsEdit = "dex-non-evm-settings-edit",

	ExchangeView = "dex-non-evm-swap-view",
	ExchangeTrade = "dex-non-evm-swap-trade",

	NotesView = "dex-non-evm-note-view",
	NotesEdit = "dex-non-evm-note-edit",

	VolumeHashView = "dex-non-evm-hash-view",
	LimitHashView = "dex-non-evm-hash-view",
	MainHashView = "dex-non-evm-hash-view",
	CounterHashView = "dex-non-evm-hash-view",

	WalletsWithdraw = "dex-non-evm-withdraw",
	WalletsGasFill = "dex-non-evm-transfer",
	WalletsView = "dex-non-evm-wallet-view",
	WalletTransfer = "dex-non-evm-transfer",

	BotManage = "dex-non-evm-manage",
}
